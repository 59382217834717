<template>
  <div class="multiselect grow relative text-[1.125rem]">
    <p class="mb-[10px]">
      {{ label }}
    </p>
    <div ref="select_container">
      <!-- Dropdown trigger -->
      <button
        role="combobox"
        :aria-expanded="isDropdownOpen"
        aria-haspopup="listbox"
        class="flex items-center w-full border-[1px] border-[#E2E2E2] p-[12px] rounded-[8px] text-left"
        @click="toggleDropdown"
      >
        <slot
          name="placeholder"
          :selected="selected"
          :initial-text="placeholder"
        >
          <div class="grow m-0 text-[1rem] text-[#626262] font-medium">
            {{ selectedPlaceholder }}
          </div>
        </slot>
        <i
          class="k-arrow-down-1 text-center cursor-pointer mt-[3px] transition"
        />
      </button>

      <!-- Dropdown options -->
      <div class="relative">
        <div
          v-if="isDropdownOpen"
          ref="options_container"
          :class="{ 'options-container': true, show: isDropdownOpen }"
          role="listbox"
          :aria-labelledby="id"
          aria-multiselectable="true"
          tabindex="-1"
          @keydown.up.prevent="navigateOptions(-1)"
          @keydown.down.prevent="navigateOptions(1)"
          @keydown.esc.prevent="closeDropdown"
        >
          <slot
            v-if="options.value.length > 0"
            :id="id"
            :options="options"
            :is-selected-all="selectAll"
            :toggleAll="toggleAll"
            :selected="selected"
          >
            <div class="checkbox-wrapper">
              <b-form-checkbox
                ref="citemsall"
                v-model="selectAll"
                :aria-describedby="id"
                :aria-controls="id"
                class="custom-checkbox-all"
                tabindex="0"
                @change="toggleAll"
              >
                Semua
              </b-form-checkbox>
            </div>
            <b-form-checkbox-group
              v-model="selected"
              :name="id"
              :aria-describedby="id"
              stacked
            >
              <template>
                <div
                  v-for="(option, index) in options.value"
                  :key="index"
                  class="checkbox-wrapper"
                >
                  <b-form-checkbox
                    ref="citems"
                    :value="option"
                  >
                    {{ options.text[index] }}
                  </b-form-checkbox>
                </div>
              </template>
            </b-form-checkbox-group>
          </slot>
          <slot
            v-if="options.value.length <= 0 || isError"
            name="error"
          >
            <p class="text-[12px] text-red-900 m-[8px]">
              Tidak ada data yang ditampilkan.
            </p>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onClickOutside } from '@vueuse/core'

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: 'Pilih Data',
    },
    placeholder: {
      type: String,
      default: 'test...',
    },
    options: {
      type: Object,
      default: () => ({
        text: [],
        value: [],
      }),
      required: true,
    },
    isError: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isDropdownOpen: false,
      selected: this.value || [],
      focusedOption: 1,
      cleanupClickOutside: null,
    }
  },
  computed: {
    selectAll: {
      get() {
        return this.selected.length === this.options.value.length
      },
      set(value) {
        if (value) {
          this.selected = [...this.options.value]
        }
        if (!value && this.selected.length <= 0) {
          this.selected = []
        }
      },
    },
    selectedPlaceholder() {
      return this.selected.length > 0
        ? `${this.selected.length} dipilih`
        : this.placeholder
    },
  },
  watch: {
    selected(newValue) {
      this.$emit('input', newValue)
    },
    focusedOption(newValue) {
      if (this.options.value.length > 0) {
        this.$nextTick(() => {
          if (newValue === 0) {
            this.$refs.citemsall.focus()
          } else {
            this.$refs.citems[newValue - 1].focus()
          }
        })
      }
    },
  },
  mounted() {
    this.cleanupClickOutside = onClickOutside(this.$refs.select_container, () => {
      if (this.isDropdownOpen) this.isDropdownOpen = false
    })
  },
  beforeDestroy() {
    if (this.cleanupClickOutside) {
      this.cleanupClickOutside()
    }
  },
  methods: {
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen
      if (this.isDropdownOpen && this.options.value.length > 0) {
        this.$nextTick(() => {
          this.$refs.citems[0].focus()
        })
      }
    },
    closeDropdown() {
      this.isDropdownOpen = false
    },
    toggleAll(allSelected) {
      this.selected = allSelected ? this.options.value : []
    },
    navigateOptions(direction) {
      const optionsLength = this.options.value.length + 1
      if (optionsLength === 0) return

      this.focusedOption = (this.focusedOption + direction + optionsLength) % optionsLength
    },
  },
}
</script>

<style scoped>
.options-container {
  position: absolute;
  max-height: 240px;
  overflow: auto;
  top: 5px;
  left: 0;
  right: 0;
  background-color: #ffffff;
  z-index: 10;
  border-radius: 16px;
  border: 1px solid #e2e2e2;
  padding: 8px;
}
.dark-layout .options-container {
  background-color: #283046;
  border-color: #3b4253;
  color: #ffffff;
}
.show {
  display: block;
  animation: slide-down 0.2s ease-out forwards;
}
.multiselect:has(.custom-control-input:focus) .k-arrow-down-1 {
  transform: rotate(180deg);
  margin-top: 0;
  margin-bottom: 3px;
}
.options-container /deep/ .checkbox-wrapper {
  padding: 8px;
  border-radius: 8px;
  transition: 0.1s all ease-in-out;
}
.options-container /deep/ .checkbox-wrapper:hover,
.options-container /deep/ .checkbox-wrapper:has(.custom-control-input:focus) {
  background-color: #eeeeee;
}
.dark-layout .options-container /deep/ .checkbox-wrapper:hover,
.dark-layout .options-container /deep/ .checkbox-wrapper:has(.custom-control-input:focus) {
  background-color: #3b4253;
}
.options-container /deep/ .checkbox-wrapper label {
  width: 100%;
}
.options-container .custom-checkbox-all {
  margin: 0;
}
@keyframes slide-down {
  0% {
    clip-path: inset(0 0 100% 0);
  }
  100% {
    clip-path: inset(0);
  }
}
</style>
