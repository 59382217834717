<template>
  <b-card body>
    <div class="d-flex justify-between">
      <div class="d-flex w-50 align-items-start">
        <b-button
          variant="primary"
          size="sm"
          class="mr-1 rounded-lg p-0"
          @click="$router.go(-1)"
        >
          <feather-icon
            size="2x"
            icon="ChevronLeftIcon"
          />
        </b-button>
        <div class="text-black font-semibold text-2xl">
          Stok Gudang
        </div>
      </div>
      <b-button
        variant="primary"
        class="px-1 rounded-lg font-bolder d-flex"
      >Export<img
        class="ml-1"
        src="@/assets/images/svg/export.svg"
        alt="export"
      >
      </b-button>
    </div>
    <b-row>
      <b-col
        lg="12"
        md="12"
      >
        <div class="my-2 p-2 border rounded-lg text-black">
          <div class="mb-1 flex gap-x-4">
            <b-img
              v-if="dataPartner.image_logo_url !== null"
              rounded="circle"
              :src="dataPartner.image_logo_url"
              alt="logo"
              style="width: 48px; height: 48px"
            />
            <b-img
              v-else
              rounded="circle"
              src="https://storage.googleapis.com/komerce/assets/icons/product-placehold.svg"
              alt="logo"
              style="width: 48px; height: 48px"
            />
            <div class="align-self-center">
              <div class="font-semibold">
                {{ dataPartner.name }}
              </div>
              <div class="">
                {{ DAY_MONTH_YEAR(dataPartner.approved_date) }}
              </div>
            </div>
          </div>
          <b-row class="">
            <b-col
              md="3"
              lg="2"
            >
              <p>Sektor Bisnis</p>
              <p>Tipe Bisnis</p>
            </b-col>
            <b-col
              md="4"
              lg="5"
            >
              <p>{{ dataPartner.business_sector === null ? '-' : dataPartner.business_sector }}</p>
              <p>{{ dataPartner.business_type === null ? '-' : dataPartner.business_type }}</p>
            </b-col>
            <b-col
              md="3"
              lg="2"
            >
              <p>Jumlah Produk</p>
              <p>Total Terjual</p>
            </b-col>
            <b-col
              md="2"
              lg="1"
            >
              <p>{{ dataPartner.product_total }}</p>
              <p>{{ dataPartner.total_sold }}</p>
            </b-col>
            <b-col
              md="12"
              lg="2"
            >
              <b-button
                size="sm"
                variant="outline-primary"
                style="height: 38px;"
                @click="chatButton(dataPartner.number_phone)"
              >Chat Partner
              </b-button>
            </b-col>
          </b-row>
        </div>

        <div class="flex justify-end">
          <b-button
            v-b-modal.modal-download-riwayat-stok
            variant="primary"
            class="mb-2"
          >
            <i
              class="k-document-download mr-[8px]"
            />
            Download Riwayat Stock
          </b-button>
        </div>

        <modal-download-riwayat-stok
          :partner-id="dataPartner.id"
          :produk="productOptions"
          :is-loading="loading"
        />

        <b-overlay
          spinner-variant="primary"
          variant="light"
          blur="0"
          opacity=".5"
          rounded="sm"
          :show="loading"
        >
          <b-table
            :fields="columnConfig"
            :items="items"
            responsive
            show-empty
            empty-text="Tidak ada data yang ditampilkan."
          >
            <template #cell(product_name)="data">
              <div class="flex gap-x-3">
                <img
                  v-if="data.item.images_path !== null"
                  :src="data.item.images_path"
                  alt="image"
                  width="48"
                  height="48"
                  class="object-contain"
                >
                <img
                  v-else
                  src="https://storage.googleapis.com/komerce/assets/icons/product-placehold.svg"
                  alt="image"
                  width="48"
                  height="48"
                  class="object-contain"
                >
                <div class="">
                  <div class="">
                    {{ data.item.product_name }}
                  </div>
                  <small class="text-primary">SKU : {{ data.item.sku }}</small>
                </div>
              </div>
            </template>
            <template #cell(variant)="data">
              <div v-if="data.item.is_variant !== 0">
                <div
                  class="mb-50"
                >
                  <span v-b-tooltip.hover.top="data.item.variant[0].variant_name.length >= 10 ? data.item.variant[0].variant_name : null">{{ truncateWithEllipsis(data.item.variant[0].variant_name) }}</span>
                </div>
                <BCollapse :id="`collapse-${String(data.index)}`">
                  <div
                    v-for="(item, idx) in data.item.variant.slice(1)"
                    :key="idx"
                    class="mb-50"
                  >
                    <span v-b-tooltip.hover.top="item.variant_name.length >= 10 ? item.variant_name : null">{{ truncateWithEllipsis(item.variant_name) }}</span>
                  </div>
                </BCollapse>
              </div>
              <div
                v-else
                class="mt-1"
              >
                -
              </div>
              <div
                v-if="data.item.variant.length > 1"
                v-b-toggle="`collapse-${String(data.index)}`"
                variant="flat-dark"
                size="sm"
                class="mt-1 flex items-center justify-end"
              >
                <span
                  class="when-opened"
                >
                  Tutup
                </span>
                <span
                  class="when-closed"
                >
                  Tampilkan Variasi
                </span>
                <feather-icon
                  icon="ChevronDownIcon"
                  class="when-closed"
                />
                <feather-icon
                  icon="ChevronUpIcon"
                  class="when-opened"
                />
              </div>
            </template>
            <template #cell(stock)="data">
              <div v-if="data.item.is_variant !== 0">
                <div
                  class="mb-50"
                >
                  <span>{{ data.item.variant[0].variant_stock }}</span>
                </div>
                <BCollapse :id="`collapse-${String(data.index)}`">
                  <div
                    v-for="(item, idx) in data.item.variant.slice(1)"
                    :key="idx"
                    class="mb-50"
                  >
                    {{ item.variant_stock }}
                  </div>
                </BCollapse>
              </div>
              <div
                v-else
                class="mt-1"
              >
                {{ data.item.stock }}
              </div>
            </template>
            <template #cell(packing_material)="data">
              <ul class="list-disc">
                <li
                  v-for="(pm, index) in data.item.packing_detail"
                  :key="index"
                >
                  {{ pm.packing_material }}
                </li>
              </ul>
            </template>
            <template #cell(fulfillment_fee)="data">
              <div class="">
                {{ data.item.fulfillment_cost | rupiah }}
              </div>
            </template>
            <template #head(additional_fee)="data">
              <div class="flex">
                <div class="">
                  {{ data.label }}
                </div>
                <img
                  id="addonPopover"
                  class="w-[15px]"
                  src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
                  alt="Komerce"
                >
                <b-popover
                  triggers="hover"
                  target="addonPopover"
                  placement="topleft"
                >
                  Biaya fullfilement untuk penambahan quantity dari suatu barang dalam 1 resi
                </b-popover>
              </div>
            </template>
            <template #cell(additional_fee)="data">
              <div class="">
                {{ data.item.addon_cost | rupiah }} / {{ data.item.addon_minimum }} Pcs
              </div>
            </template>
            <template #cell(sold)="data">
              <div v-if="data.item.is_variant !== 0">
                <div
                  class="mb-50"
                >
                  <span>{{ data.item.variant[0].variant_sold }}</span>
                </div>
                <BCollapse :id="`collapse-${String(data.index)}`">
                  <div
                    v-for="(item, idx) in data.item.variant.slice(1)"
                    :key="idx"
                    class="mb-50"
                  >
                    {{ item.variant_sold === null ? 0 : item.variant_sold }}
                  </div>
                </BCollapse>
              </div>
              <div
                v-else
                class="mt-1"
              >
                {{ data.item.sold === null ? 0 : data.item.sold }}
              </div>
            </template>
            <template #row-details="data">
              <b-row
                class="-mt-[1rem] pl-[4.3rem]"
              >
                <b-col cols="12">
                  <div class="flex">
                    <div
                      v-b-toggle="`bundling-${String(data.index)}`"
                      class="flex items-center gap-[4px] py-[4px] px-[8px] rounded-[12px] !bg-[#08A0F7] text-white font-[500] cursor-pointer"
                    >
                      <img
                        src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/gift.svg"
                        alt="bundling"
                        width="16"
                      >
                      <div>Bundling</div>
                    </div>
                  </div>
                  <b-collapse
                    :id="`bundling-${String(data.index)}`"
                  >
                    <div class="border-l-[2px] h-min p-[16px] ml-[3.5rem]">
                      <div class="border rounded-t w-[70%]">
                        <div class="bg-[#FFECE9] py-[8px] px-[24px] rounded-t text-black font-[500]">
                          Produk
                        </div>
                        <b-table
                          :items="data.item.bundle_component"
                          :fields="fieldsBundling"
                        >
                          <template #cell(no)="data">
                            {{ data.index + 1 }}
                          </template>
                          <template #cell(product_name)="data">
                            <div class="flex gap-[16px] items-start">
                              <img
                                :src="data.item.product_image[0] ? data.item.product_image[0].images_path : 'https://storage.googleapis.com/komerce/assets/icons/product-placehold.svg'"
                                alt="product"
                                width="52"
                                height="52"
                              >
                              <div class="space-y-[4px]">
                                <div class="text-[16px]">
                                  {{ data.item.product_name }}
                                </div>
                                <div class="text-[14px]">
                                  SKU: {{ data.item.sku }}
                                </div>
                              </div>
                            </div>
                          </template>
                        </b-table>
                      </div>
                    </div>
                    <div class="flex ml-[0.8rem]">
                      <div
                        v-b-toggle="`bundling-${String(data.index)}`"
                        class="flex items-center gap-[4px] py-[4px] px-[8px] rounded-[12px] !bg-[#F95031] text-white font-[500] cursor-pointer"
                      >
                        <feather-icon
                          icon="ChevronUpIcon"
                        />
                        <div>Tutup</div>
                      </div>
                    </div>
                  </b-collapse>
                </b-col>
              </b-row>
            </template>
          </b-table>
        </b-overlay>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import provider from '@/provider/provider'
import { STOCK } from '@/provider/url'
import filters from '@/libs/filters'
import chatButton from '@/libs/chatButton'
import { DAY_MONTH_YEAR } from '@/libs/filterDate'
import { columnConfig, fieldsBundling } from './config'
import ModalDownloadRiwayatStok from './ModalDownloadRiwayatStok.vue'

export default {
  components: {
    ModalDownloadRiwayatStok,
  },
  filters: { rupiah: filters.rupiah },
  data() {
    return {
      chatButton,
      DAY_MONTH_YEAR,
      columnConfig,
      items: [],
      partnerId: this.$route.params.id,
      dataPartner: this.$route.params.datapartner,
      loading: false,
      fieldsBundling,
    }
  },
  computed: {
    productOptions() {
      const result = {
        text: [],
        value: [],
      }
      this.items.forEach(v => {
        if (!v.is_bundling) {
          result.text.push(v.product_name)
          result.value.push(v.id)
        }
      })
      return result
    },
  },
  mounted() {
    if (this.dataPartner === undefined) {
      this.$router.go(-1)
    }
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      provider
        .single(`${STOCK}/${this.partnerId}/partner`)
        .then(({ data }) => {
          const updatedData = data.map(item => ({
            ...item,
            _showDetails: item.is_bundling === 1,
          }))
          this.items = updatedData
          this.loading = false
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failed',
              text: 'Galat detail information',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
          this.loading = false
        })
    },
    truncateWithEllipsis(text) {
      return text.length <= 10 ? text : `${text.slice(0, 10)}...`
    },
  },
}
</script>
<style lang="scss" scoped>
.btn-collapse {
  background-color: transparent !important;
  color: #08A0F7 !important;
  border: none;
  font-size: 16px;
}
[dir] .when-closed {
  display: inline-block;
}

[dir] .when-opened {
  display: inline-block;
}

@media screen and (max-width: 767px) {
  .table-container {
  overflow-x: scroll;
  }
  .table {
    width: 750px;
    table-layout: fixed;
  }
}
 .collapsed > .when-opened,
    :not(.collapsed) > .when-closed {
        display: none;
    }
</style>
